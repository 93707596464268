import {
    chakra,
    HStack,
    VStack,
    AccordionButton,
    AccordionPanel,
    AccordionItem,
    AccordionIcon,
    Spacer,
    Divider,
    useColorMode,
    Stack,
    Image,
} from '@chakra-ui/react';
import { useContext } from 'react';
import BigNumber from 'bignumber.js';
import { createContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useBreakpointValue } from '@chakra-ui/react';

import PositionList from './PositionList';
import { APR } from '../../../components/APR';
import { TVL } from '../../../components/TVL';
import { Earned } from '../../../components/Earned';
import { Rewards } from '../../../components/Rewards';
import { FeeRate } from '../../../../Trade/components/FeeRate';
import { getColorThemeSelector } from '../../../../../utils/funcs';
import { i_text_copy, i_text_copy_bold } from '../../../../../style';
import { TokenIcons } from '../../../../Trade/components/TokenIcons';
import Card from '../../../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { useWeb3WithDefault } from '../../../../../hooks/useWeb3WithDefault';
import { FarmRefreshHandle } from '../../../iZiSwap/FixRange/components/PoolList';
import { CustomTag } from '../../../../../iZUMi-UI-toolkit/src/components/Tag/Tag';
import { CardTag } from '../../../../../iZUMi-UI-toolkit/src/components/CardTag/CardTag';
import { ToastLink, useCustomToast } from '../../../../../iZUMi-UI-toolkit/src/components/Toast/Toast';
import CardTagContainer from '../../../../../iZUMi-UI-toolkit/src/components/CardTag/Container';
import { PoolEntryState } from '../../../../../state/models/farm/iZiSwap/dynamicRange/types';
import CustomButton from '../../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { buildSendingParams, getMiningDynamicRangeiZiBoostContract } from '../../../../../utils/contractHelpers';
import { useGasPrice } from '../../../../../hooks/useGasPrice';
import { ChainId, FarmDynamicRangeiZiContractVersion } from '../../../../../types/mod';
import useIsMobile from '../../../../../iZUMi-UI-toolkit/src/hooks/useIsMobile';
import useWindowDimensions from '../../../../../hooks/useWindowDimension';
import { getChain, getTxLink } from '../../../../../config/chains';

const PoolListEntryContext = createContext<PoolEntryState | null>(null);
export const PoolListEntryProvider = PoolListEntryContext.Provider;

export function usePoolListEntry(): PoolEntryState {
    return useContext(PoolListEntryContext) as PoolEntryState;
}

function harvestAll(
    chainId: ChainId,
    web3: any,
    account: any,
    miningAddress: string,
    contractVersion: FarmDynamicRangeiZiContractVersion,
    gas: string,
    gasPrice: string | number
) {
    const miningContract = getMiningDynamicRangeiZiBoostContract(miningAddress, web3, contractVersion);

    return miningContract?.methods.collectAllTokens().send(
        buildSendingParams(
            chainId,
            {
                from: account,
                gas,
                maxFeePerGas: gasPrice,
            },
            gasPrice
        )
    );
}

function estimateHarvestAllGas(web3: any, account: any, miningAddress: string, contractVersion: FarmDynamicRangeiZiContractVersion) {
    const miningContract = getMiningDynamicRangeiZiBoostContract(miningAddress, web3, contractVersion);

    return miningContract?.methods.collectAllTokens().estimateGas({ from: account });
}

export const PoolListEntry = chakra(({ refreshPoolListDataAndPosition, refreshPosition, type, ...rest }) => {
    const { t } = useTranslation();
    const entry = usePoolListEntry();
    const { width } = useWindowDimensions();

    const oneLineMode = useBreakpointValue({ base: false, xxl: true, '2xl': true })!;

    const { account, web3, chainId } = useWeb3WithDefault();
    const needApprove = false;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const toast = useCustomToast();
    const isMobile = useIsMobile();
    const { gasPrice } = useGasPrice();

    const isFarming = entry.stakedPositionList && entry.stakedPositionList.length > 0;

    const onHarvest = () => {
        estimateHarvestAllGas(web3, account, entry.meta.miningContract, entry.meta.contractVersion).then((gas: number) => {
            const chain = getChain(chainId);
            const toastLink = {} as ToastLink;
            const gasLimit = new BigNumber(gas * 1.1).toFixed(0);
            harvestAll(chainId, web3, account, entry.meta.miningContract, entry.meta.contractVersion, gasLimit, gasPrice)
                .on('transactionHash', (hash: string) => {
                    if (chain) {
                        toastLink.title = 'View on ' + chain.name;
                        toastLink.link = getTxLink(hash, chain);
                    }
                    toast('info', 'Collecting all rewards from the mining contract...', undefined, toastLink);
                })
                .then((e: any) => {
                    toast('success', 'Collect reward successfully.', undefined, toastLink);
                    console.log(e);
                    refreshPosition?.();
                })
                .catch((e: any) => {
                    console.log(e);
                });
        });
    };

    const ApproveButton = <CustomButton variant="tertiary" text="approve" className={i_text_copy_bold} w="75px" h="30px" />;

    const Tags = (
        <>
            {entry.meta.iZiBoost &&
                (isMobile ? (
                    <CustomTag
                        icon={colorTheme('/assets/farm/lightBoost.svg', '/assets/farm/darkBoost.svg')}
                        text="iZi Boost"
                        variant="purple"
                        bg={colorTheme('secondary.50', '#581577')}
                        px="18px"
                    />
                ) : (
                    <CustomTag
                        icon={colorTheme('/assets/farm/lightBoost.svg', '/assets/farm/darkBoost.svg')}
                        text="iZi Boost"
                        variant="purple"
                    />
                ))}
            {entry.meta.veiZiBoost &&
                (isMobile ? (
                    <CustomTag
                        icon={colorTheme('/assets/farm/lightBoost.svg', '/assets/farm/darkBoost.svg')}
                        text="veiZi Boost"
                        variant="purple"
                        bg={colorTheme('secondary.50', '#581577')}
                        px="18px"
                    />
                ) : (
                    <CustomTag
                        icon={colorTheme('/assets/farm/lightBoost.svg', '/assets/farm/darkBoost.svg')}
                        text="veiZi Boost"
                        variant="purple"
                    />
                ))}
            {/* {isMobile ? (
                <CustomTag
                    icon={colorTheme('/assets/farm/lightProtect.svg', '/assets/farm/darkProtect.svg')}
                    text="Impermanent Loss Protect"
                    variant="blue"
                    bg={colorTheme('primary.50', '#3D4275')}
                    px="18px"
                />
            ) : (
                <CustomTag
                    icon={colorTheme('/assets/farm/lightProtect.svg', '/assets/farm/darkProtect.svg')}
                    text="Impermanent Loss Protect"
                    variant="blue"
                />
            )} */}
        </>
    );

    const DetailsButton = (
        <>
            {!isMobile && <Spacer />}
            <AccordionButton
                w="unset"
                color="secondary.500"
                className={i_text_copy}
                fontSize="14px !important"
                ml={oneLineMode ? 'undefined' : { base: '10px !important', sm: '-15px !important' }}
                px="4px"
            >
                {/* {!oneLineMode && <Box>Details</Box>} */}
                <AccordionIcon />
            </AccordionButton>
        </>
    );

    return type === 'mobile' ? (
        <Card
            {...rest}
            position="relative"
            border={account && isFarming ? '1px' : '0'}
            borderColor="primary.300"
            bg={colorTheme('#FFFFFF', 'linear-gradient(180deg, #2E274A 0%, #2A2743 100%)')}
        >
            <CardTagContainer>
                {account && isFarming && <CardTag variant="blue" text={t('$ Farming')} />}
                {account && entry.meta.iZiBoost && <CardTag variant="purple" text={t('iZi Boost')} />}
                {account && entry.meta.veiZiBoost && <CardTag variant="purple" text={t('veiZi Boost')} />}
            </CardTagContainer>

            <AccordionItem border="0">
                <HStack pt="21px" spacing="20px" minW={{ base: '100%', sm: '680px' }} bg={colorTheme('#FFFFFF', '#25203B')}>
                    <Stack spacing="8px" w="100%" direction={{ base: 'column', sm: 'row' }}>
                        <HStack spacing={{ base: '18px', xlp1: '28px' }} flexShrink={0} px="30px">
                            <TokenIcons tokenA={entry.meta.tokenA} tokenB={entry.meta.tokenB} initialToggle={entry.meta.initialToggle} />
                            <FeeRate
                                tokenA={entry.meta.tokenA}
                                tokenB={entry.meta.tokenB}
                                feeTier={entry.meta.feeTier}
                                initialToggle={entry.meta.initialToggle}
                            />

                            <Image
                                boxSize="30px"
                                src={process.env.PUBLIC_URL + '/assets/sidebar/logo.svg'}
                                fallbackSrc={process.env.PUBLIC_URL + '/assets/sidebar/logo.svg'}
                                ml="auto !important"
                            ></Image>
                            {isMobile && DetailsButton}
                        </HStack>
                        <Stack
                            bg={colorTheme(
                                'linear-gradient(180deg, #F1F1F1 0%, #FFFFFF 100%)',
                                'linear-gradient(180deg, #2E274A 0%, #2A2743 100%)'
                            )}
                            mt="13px !important"
                            pb="16px"
                        >
                            <HStack w="100%" mt={{ base: '11px !important', sm: 'unset !important' }} spacing="28px" px="30px">
                                <APR
                                    w={{ base: '140px', sm: '165px' }}
                                    apr={entry.data.apr}
                                    variant={entry.meta.iZiBoost || entry.meta.veiZiBoost ? 'range' : 'base'}
                                    meta={entry.meta}
                                    type="iZiSwap"
                                />
                                <TVL tvl={entry.data.tvl} type="iZiSwap" />
                            </HStack>
                            <Rewards
                                oneLineMode={oneLineMode}
                                rewards={entry.data.reward || []}
                                type="mobile"
                                useTimestamp={entry.meta.useTimestamp}
                            />
                        </Stack>
                    </Stack>
                    {oneLineMode && (
                        <>
                            <Rewards oneLineMode={oneLineMode} rewards={entry.data.reward || []} useTimestamp={entry.meta.useTimestamp} />
                            {account ? (
                                <>
                                    {!needApprove ? (
                                        <Earned
                                            onHarvest={() => {
                                                onHarvest();
                                            }}
                                            entry={entry}
                                            gridRow="span 3"
                                            justifySelf="end"
                                            my="-10px !important"
                                            earned={
                                                entry.userData.earned &&
                                                entry.userData.earned.map((item) => {
                                                    return parseFloat(item);
                                                })
                                            }
                                        />
                                    ) : (
                                        ApproveButton
                                    )}
                                    {DetailsButton}
                                </>
                            ) : (
                                <VStack alignItems="start">{Tags}</VStack>
                            )}
                        </>
                    )}
                    {!oneLineMode && !isMobile && DetailsButton}
                </HStack>
                <AccordionPanel mt="-8px">
                    {!oneLineMode && (
                        <Stack spacing="20px" alignItems="center" justifyContent="space-between" direction={{ base: 'column', sm: 'row' }}>
                            <VStack w="100%" alignItems="start">
                                {Tags}
                            </VStack>
                            {account &&
                                (!needApprove ? (
                                    <Earned
                                        onHarvest={() => {
                                            onHarvest();
                                        }}
                                        entry={entry}
                                        h="62px"
                                        gridRow="span 3"
                                        justifySelf="end"
                                        border={colorTheme('1px solid #EBE6F0', '')}
                                        borderRadius="4px"
                                        p="11px 18px 11px 18px"
                                        my="12px !important"
                                        earned={
                                            entry.userData.earned &&
                                            entry.userData.earned.map((item) => {
                                                return parseFloat(item);
                                            })
                                        }
                                        havestBtStyle={{ bg: colorTheme('#E2EAF2', '#363457'), color: colorTheme('#687692', '#B6C6E7') }}
                                    />
                                ) : (
                                    ApproveButton
                                ))}
                        </Stack>
                    )}
                    {account && (
                        <>
                            <Divider mt="5px" mb="13px" />
                            <PositionList
                                entry={entry}
                                refreshPosition={refreshPosition}
                                refreshPoolListDataAndPosition={refreshPoolListDataAndPosition}
                                type="mobile"
                            />
                        </>
                    )}
                </AccordionPanel>
            </AccordionItem>
        </Card>
    ) : (
        <Card {...rest} position="relative" border={account && isFarming ? '1px' : '0'} borderColor="primary.300">
            <CardTagContainer>
                {account && isFarming && <CardTag variant="blue" text={t('$ Farming')} />}
                {account && entry.meta.iZiBoost && <CardTag variant="purple" text={t('iZi Boost')} />}
                {account && entry.meta.veiZiBoost && <CardTag variant="purple" text={t('veiZi Boost')} />}
            </CardTagContainer>

            <AccordionItem border="0">
                <HStack
                    py={{ base: '24px', sm: '18px' }}
                    pl={{ base: '30px', sm: '40px' }}
                    pr="30px"
                    spacing="20px"
                    minW={{ base: '100%', sm: '680px' }}
                >
                    <Stack spacing="28px" flexShrink={0} direction={{ base: 'column', sm: 'row' }}>
                        <HStack spacing={{ base: '18px', xlp1: '28px' }} flexShrink={0}>
                            <TokenIcons tokenA={entry.meta.tokenA} tokenB={entry.meta.tokenB} initialToggle={entry.meta.initialToggle} />
                            <FeeRate
                                tokenA={entry.meta.tokenA}
                                tokenB={entry.meta.tokenB}
                                feeTier={entry.meta.feeTier}
                                initialToggle={entry.meta.initialToggle}
                            />
                            {isMobile && DetailsButton}
                        </HStack>
                        <HStack mt={{ base: '15px !important', sm: 'unset !important' }} spacing="28px">
                            <APR
                                w={{ base: '140px', sm: '165px' }}
                                apr={entry.data.apr}
                                variant={entry.meta.iZiBoost || entry.meta.veiZiBoost ? 'range' : 'base'}
                                meta={entry.meta}
                                type="iZiSwap"
                            />
                            {/* <Liquidity liquidity={entry.data.capital} /> */}
                            <TVL tvl={entry.data.tvl} type="iZiSwap" />
                        </HStack>
                    </Stack>
                    {oneLineMode && (
                        <>
                            <Rewards oneLineMode={oneLineMode} rewards={entry.data.reward || []} useTimestamp={entry.meta.useTimestamp} />
                            {account ? (
                                <>
                                    {!needApprove ? (
                                        <Earned
                                            onHarvest={() => {
                                                onHarvest();
                                            }}
                                            entry={entry}
                                            gridRow="span 3"
                                            justifySelf="end"
                                            my="-10px !important"
                                            earned={
                                                entry.userData.earned &&
                                                entry.userData.earned.map((item) => {
                                                    return parseFloat(item);
                                                })
                                            }
                                        />
                                    ) : (
                                        ApproveButton
                                    )}
                                    {DetailsButton}
                                </>
                            ) : (
                                <VStack alignItems="start">{Tags}</VStack>
                            )}
                        </>
                    )}
                    {!oneLineMode && !isMobile && DetailsButton}
                </HStack>
                <AccordionPanel>
                    {!oneLineMode && (
                        <Stack
                            spacing="20px"
                            pl={{ base: '30px', sm: '50px' }}
                            alignItems="center"
                            justifyContent="space-between"
                            pr="30px"
                            direction={{ base: 'column', sm: 'row' }}
                        >
                            <VStack alignItems="start">
                                <Rewards
                                    oneLineMode={oneLineMode}
                                    rewards={entry.data.reward || []}
                                    useTimestamp={entry.meta.useTimestamp}
                                />
                                {Tags}
                            </VStack>
                            {account &&
                                (!needApprove ? (
                                    <Earned
                                        onHarvest={() => {
                                            onHarvest();
                                        }}
                                        entry={entry}
                                        gridRow="span 3"
                                        justifySelf="end"
                                        my={{ base: '10px !important', sm: '-10px!important' }}
                                        earned={
                                            entry.userData.earned &&
                                            entry.userData.earned.map((item) => {
                                                return parseFloat(item);
                                            })
                                        }
                                    />
                                ) : (
                                    ApproveButton
                                ))}
                        </Stack>
                    )}
                    {account && (
                        <>
                            <Divider mt="14px" mb="26px" />
                            <Stack w="100%" pl={{ base: '0px', xl: '50px', xxl: '0px' }}>
                                <PositionList
                                    entry={entry}
                                    refreshPosition={refreshPosition}
                                    refreshPoolListDataAndPosition={refreshPoolListDataAndPosition}
                                />
                            </Stack>
                        </>
                    )}
                </AccordionPanel>
            </AccordionItem>
        </Card>
    );
});

const Wrapper = chakra(({ entry, ...rest }: { entry: PoolEntryState } & FarmRefreshHandle) => {
    return (
        <PoolListEntryProvider value={entry}>
            <PoolListEntry {...rest} />
        </PoolListEntryProvider>
    );
});

export default Wrapper;
