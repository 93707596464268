import { HStack, Text, Stack, BoxProps, useColorMode } from '@chakra-ui/react';
import { Time } from 'lightweight-charts';
import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import tokens, { isGasOrWrappedGasToken, tokenSymbol2token } from '../../../../../config/tokens';
import { TokenInfoFormatted, useTokenListFormatted } from '../../../../../hooks/useTokenListFormatted';
import { useWeb3WithDefault } from '../../../../../hooks/useWeb3WithDefault';
import useIsMobile from '../../../../../iZUMi-UI-toolkit/src/hooks/useIsMobile';
import { getIziSwapKLinesRecord, iZiSwapKLinesRecordEnum } from '../../../../../net/iZUMi-endpoints/src/restful/api/analytics/izumiKlines';
import {
    getIziSwapGenericSummaryRecord,
    ResponseIziSwapPoolSummaryRecord,
    SummaryRecordTypeEnum,
} from '../../../../../net/iZUMi-endpoints/src/restful/izumiSwapSummary';
import { useGasToken } from '../../../../../state/models/hooks/useGasToken';
import { getBaseQuoteOrder } from '../../../../../state/models/pro/proOrderFormState/funcs';
import { getSafeTokenPrice } from '../../../../../state/models/token/funcs';
import { RootState } from '../../../../../state/store';
import { noto_t1_bold, noto_t2_bold, noto_t3, noto_t3_bold } from '../../../../../style';
import { TokenSymbol } from '../../../../../types/mod';
import { getColorThemeSelector } from '../../../../../utils/funcs';
import { formatNumber } from '../../../../../utils/tokenMath';
import { ResponseKlineInfoMetaWithReverse } from '../KlineInfo';

type TokenChangeProps = {
    pools: ResponseKlineInfoMetaWithReverse[];
} & BoxProps;

const TokenChange: React.FC<TokenChangeProps> = (props) => {
    const { pools, ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const isMobile = useIsMobile();
    const { t } = useTranslation();

    const { chainId } = useWeb3WithDefault();
    const { token } = useSelector((state: RootState) => state);

    const { pro, proControlState } = useSelector((state: RootState) => state);
    const { loading, tokenList } = useTokenListFormatted();

    const tokenXIsGasToken = isGasOrWrappedGasToken(pro.tokenX, chainId);
    const tokenYIsGasToken = isGasOrWrappedGasToken(pro.tokenY, chainId);
    const gasToken = useGasToken();

    const getToken = (symbol: string) => {
        const tempToken = {
            ...tokenList.find((e) => e.symbol === symbol),
        };
        if (tokens[symbol as TokenSymbol]) {
            return tokenSymbol2token(symbol, chainId);
        }
        return tempToken;
    };

    const [changeByDay, setChangeByDay] = useState(0);
    const [highByDay, setHighByDay] = useState('');
    const [lowByDay, setLowByDay] = useState('');
    const [volX_day, setVolX_day] = useState('');
    const [volY_day, setVolY_day] = useState('');

    const tokenEntry = useMemo(() => {
        return pools.find((item) => item.tokenX.symbol === pro.tokenX.symbol && item.tokenY.symbol === pro.tokenY.symbol);
    }, [pools, pro.tokenX, pro.tokenY]);

    useEffect(() => {
        if (loading) {
            return;
        }
        setChangeByDay(tokenEntry?.pool.dayPriceChange ?? 0);
    }, [chainId, loading, tokenEntry]);

    useEffect(() => {
        if (loading || !pro.pool) {
            return;
        }

        getIziSwapGenericSummaryRecord<ResponseIziSwapPoolSummaryRecord[]>({
            chain_id: chainId,
            type: SummaryRecordTypeEnum.IZI_POOL_LATEST,
            contract_addr: pro.pool,
        }).then((r) => {
            const data = r.data.is_success ? r.data.data : [];
            if (data && data.length > 0) {
                const tokenA = getToken(data[0].tokenX) as TokenInfoFormatted;
                const tokenB = getToken(data[0].tokenY) as TokenInfoFormatted;

                const { tokenAIsQuoteToken } = getBaseQuoteOrder(tokenA, tokenB, chainId);
                if (tokenAIsQuoteToken) {
                    setVolX_day(String(formatNumber(data[0].volAmtX_day)));
                    setVolY_day(String(formatNumber(data[0].volAmtY_day)));
                } else {
                    setVolX_day(String(formatNumber(data[0].volAmtY_day)));
                    setVolY_day(String(formatNumber(data[0].volAmtX_day)));
                }
            }
        });

        getIziSwapKLinesRecord({
            chain_id: chainId,
            identity: pro.pool,
            interval: 'D' as iZiSwapKLinesRecordEnum,
            order_by: '-time',
            page_size: 1,
        }).then((r) => {
            if (!r.data.is_success) {
                return;
            }
            const result = r.data.data.map((item) => {
                return {
                    time: item.timestamp as Time,
                    open: item.open,
                    high: Number(item.high),
                    low: Number(item.low),
                    close: item.close,
                };
            });
            if (result) {
                if (result.length > 0) {
                    if (pro.isReverseToken) {
                        setHighByDay(formatNumber(1 / result[0].low, 2, 2, true));
                        setLowByDay(formatNumber(1 / result[0].high, 2, 2, true));
                    } else {
                        setHighByDay(formatNumber(result[0].high, 2, 2, true));
                        setLowByDay(formatNumber(result[0].low, 2, 2, true));
                    }
                }
            }
        });
    }, [chainId, pro.pool, tokenList]);

    const changeBlock = (title: string, data: string | number, width?: string, symbol?: string) =>
        title === '24H Change' ? (
            <Stack spacing={{ base: '3px', sm: '6px' }}>
                <Text className={noto_t3} whiteSpace="nowrap" color="#B3B3B3">
                    {t(title)}
                </Text>
                <Text className={noto_t2_bold} color={changeByDay >= 0 ? '#12931F' : '#CB1414'} letterSpacing="-0.06em">
                    {(data >= 0 ? '+ ' : '- ') + Math.abs((data as number) * 100).toFixed(2) + ' %'}
                </Text>
            </Stack>
        ) : (
            <Stack w={width} spacing={{ base: '3px', sm: '6px' }}>
                <Text className={noto_t3} color="#B3B3B3">
                    {t(title)}
                </Text>
                {symbol && (
                    <Text className={noto_t3} color="#B3B3B3">
                        {symbol}
                    </Text>
                )}
                <Text className={isMobile ? noto_t3_bold : noto_t3} color={colorTheme('#312314', '#EEF0F3')}>
                    {data}
                </Text>
            </Stack>
        );

    return isMobile ? (
        <HStack h="100%" px="20px" spacing="40px" {...rest}>
            <Stack spacing={{ base: '18px' }}>
                <Stack spacing="3px">
                    <Text className={isMobile ? noto_t1_bold : noto_t2_bold} color={changeByDay >= 0 ? '#12931F' : '#CB1414'}>
                        {formatNumber(getSafeTokenPrice(token, pro.tokenX.symbol), 2, 5)}
                    </Text>
                    <HStack color={colorTheme('#83789B', '#EEF0F3')}>
                        <Text className={noto_t3} whiteSpace="nowrap">
                            {'≈ '}
                            {formatNumber(getSafeTokenPrice(token, pro.tokenX.symbol))}
                            {' USD'}
                        </Text>
                    </HStack>
                </Stack>
                {changeBlock('24H Change', changeByDay)}
            </Stack>

            <Stack>
                <HStack>
                    {changeBlock('24H High', highByDay, '106px')}
                    {changeBlock('24H Low', lowByDay, '106px')}
                </HStack>
                <HStack>
                    {changeBlock(
                        '24H Turnover',
                        volX_day,
                        '106px',
                        '(' + (tokenYIsGasToken && proControlState.useGasToken ? gasToken.symbol : pro.tokenY.symbol) + ')'
                    )}
                    {changeBlock(
                        '24H Volume',
                        volY_day,
                        '106px',
                        '(' + (tokenXIsGasToken && proControlState.useGasToken ? gasToken.symbol : pro.tokenX.symbol) + ')'
                    )}
                </HStack>
            </Stack>
        </HStack>
    ) : (
        <HStack h="100%" {...rest}>
            <Stack spacing="6px">
                <Text className={noto_t2_bold} color={changeByDay >= 0 ? '#12931F' : '#CB1414'} letterSpacing="-0.02em">
                    {tokenEntry && formatNumber(tokenEntry.pool.latestDealPrice, 2, 2, true)}
                </Text>
                <HStack color={colorTheme('#83789B', '#EEF0F3')}>
                    <Text className={noto_t3}>
                        {'≈ '}
                        {tokenEntry && formatNumber(tokenEntry.pool.latestDealPrice, 2, 2, true)}
                        {' USD'}
                    </Text>
                </HStack>
            </Stack>
            <HStack ml="34px !important" spacing={{ base: '20px', xl: '50px' }}>
                {changeBlock('24H Change', changeByDay)}
                {changeBlock('24H High', highByDay)}
                {changeBlock('24H Low', lowByDay)}
                {changeBlock(
                    '24H Turnover(' + (tokenYIsGasToken && proControlState.useGasToken ? gasToken.symbol : pro.tokenY.symbol) + ')',
                    volX_day
                )}
                {changeBlock(
                    '24H Volume(' + (tokenXIsGasToken && proControlState.useGasToken ? gasToken.symbol : pro.tokenX.symbol) + ')',
                    volY_day
                )}
            </HStack>
        </HStack>
    );
};

export default TokenChange;
