import { ChainId } from '../types/mod';
import { getScanUrl } from './chains';

export const links = {
    docs: 'https://docs.izumi.finance',
    developerDocs: 'https://developer.izumi.finance',
    github: 'https://github.com/IzumiFinance/',
    miningProtocol:
        'https://izumi-finance.medium.com/announcement-of-izi-eth-and-usdc-usdt-liquidity-mining-program-on-uniswap-v3-397705303e08',
    dswapProtocol: 'https://assets.izumi.finance/paper/dswap.pdf',
    telegram: 'https://t.me/joinchat/e7idO_QU_21iY2E9',
    telegramCN: 'https://t.me/iZUMi_Finance_CN',
    telegramEN: 'https://t.me/izumifinance',
    telegramChannel: 'https://t.me/iZUMi_Finance_Channel',
    twitter: 'https://twitter.com/izumi_Finance',
    medium: 'https://izumi-finance.medium.com',
    discord: 'https://discord.gg/izumifinance',
    reddit: 'https://www.reddit.com/r/izumiFinance/',
    defipulse: 'https://www.defipulse.com',
    research: 'https://docs.izumi.finance/research',
    iZiToken: 'https://etherscan.io/address/0x9ad37205d608b8b219e6a2573f922094cec5c200#readContract',
    mediaKit: 'https://drive.google.com/drive/folders/1XLvJbB5y6DWkl7O8qPXe1DJqSsaXVl5F?usp=sharing',
    liquidBoxDoc: 'https://docs.izumi.finance/product/liquidbox/why-do-protocols-need-liquidbox-to-gain-liquidity',
    iZiBuyLink: {
        [ChainId.Arbitrum]:
            'https://app.uniswap.org/#/swap?outputCurrency=0x60D01EC2D5E98Ac51C8B4cF84DfCCE98D527c747&inputCurrency=ETH&exactAmount=1&exactField=input',
        [ChainId.Matic]:
            'https://app.uniswap.org/#/swap?outputCurrency=0x60D01EC2D5E98Ac51C8B4cF84DfCCE98D527c747&inputCurrency=ETH&exactAmount=1&exactField=input',
        [ChainId.EthereumMainnet]:
            'https://app.uniswap.org/#/swap?outputCurrency=0x9ad37205d608B8b219e6a2573f922094CEc5c200&inputCurrency=ETH&exactAmount=1&exactField=input',
        [ChainId.BSC]: 'https://izumi.finance/trade/swap?tokenTo=0x60d01ec2d5e98ac51c8b4cf84dfcce98d527c747&chainId=56',
        [ChainId.ZkSyncEra]: 'https://zksync.izumi.finance/swap?tokenTo=0x16A9494e257703797D747540f01683952547EE5b&chainId=324',
    } as any,
    disclaimer: 'https://docs.izumi.finance/disclaimer',
    gateiZiLink: 'https://www.gateio.ch/cn/trade/IZI_USDT',
    bybitiZiLink: 'https://www.bybit.com/en-US/trade/spot/IZI/USDT',
    mexciZiLink: 'https://www.mexc.com/zh-CN/exchange/IZI_USDT',
    swftiZiLink: 'https://defi.swft.pro?sourceFlag=izietc',
    analyticLink: process.env.REACT_APP_ENV === 'development' ? 'https://analytic-alpha.izumi.finance' : 'https://analytics.izumi.finance',
    veiZilink: 'https://etherscan.io/address/0xb56a454d8dac2ad4cb82337887717a2a427fcd00',
    cbridgeLink: 'https://cbridge.celer.network/1/56/iZi',
    iPointsAnnouncement: 'https://izumi-finance.medium.com/iziswaps-ipoints-system-has-launched-on-scroll-6c17a280380e',
    legacyLink: 'https://zksync-legacy.izumi.finance/liquidity',
    legacySwapLink: 'https://zksync-legacy.izumi.finance/swap',
};

export const getNftUrl = (chainId: ChainId, contractAddress: string, nftId: string): string => {
    return getScanUrl(chainId) + 'token/' + contractAddress + '?a=' + nftId;
};
