import { Divider, HStack, Stack, Text, Image, useColorMode, Center, VStack } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTokenListFormatted } from '../../../../hooks/useTokenListFormatted';
import { useWeb3WithDefault } from '../../../../hooks/useWeb3WithDefault';
import Card from '../../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { ResponseIPointsInfo } from '../../../../net/iZUMi-endpoints/src/restful/izumiGame';
import { i_text_copy } from '../../../../style';
import { getColorThemeSelector } from '../../../../utils/funcs';
import { formatNumber } from '../../../../utils/tokenMath';
import { Loading, LoadingEnum } from '../../../components/Loading';
import LPStatus, { LPStatusDayEnum, LPStatusValueEnum } from './components/LPStatus';
type WeeklyRewardProps = {
    networkLoading: boolean;
    iPointsInfo: ResponseIPointsInfo;
};
const WeeklyReward: React.FC<WeeklyRewardProps> = (props) => {
    const { networkLoading, iPointsInfo } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { account } = useWeb3WithDefault();
    const { tokenList } = useTokenListFormatted();
    const { t } = useTranslation();
    const totalWeeklyPoint = useMemo(() => {
        if (!iPointsInfo || !iPointsInfo.swapVolume || !iPointsInfo.lpStatus) {
            return 0;
        }
        return (
            iPointsInfo.swapVolume.reduce((accumulator, currentValue) => {
                return accumulator + currentValue.rewardIpoints;
            }, 0) +
            iPointsInfo.lpStatus.reduce((accumulator, currentValue) => {
                return accumulator + currentValue.rewardIpoints;
            }, 0)
        );
    }, [iPointsInfo]);
    const totalSwapVolume = useMemo(() => {
        if (!iPointsInfo || !iPointsInfo.swapVolume) {
            return 0;
        }
        const filteredArray = iPointsInfo.swapVolume.find((item) => item.spotToken === null);
        if (filteredArray) {
            return filteredArray?.swapVolume;
        }
        return iPointsInfo.swapVolume.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.swapVolume;
        }, 0);
    }, [iPointsInfo]);
    const totalSwapPoints = useMemo(() => {
        if (!iPointsInfo || !iPointsInfo.swapVolume) {
            return 0;
        }
        const filteredArray = iPointsInfo.swapVolume.find((item) => item.spotToken === null);
        if (filteredArray) {
            return filteredArray.rewardIpoints;
        }
        return iPointsInfo.swapVolume.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.rewardIpoints;
        }, 0);
    }, [iPointsInfo]);

    const spotTokens = useMemo(() => {
        if (!iPointsInfo || !iPointsInfo.swapVolume) {
            return [];
        }
        const filteredArray = iPointsInfo.swapVolume.filter((item) => item.spotToken !== null);
        if (filteredArray) {
            return filteredArray;
        } else {
            return [];
        }
    }, [iPointsInfo]);
    const [is3day, setIs3day] = useState(false);
    const [is5day, setIs5day] = useState(false);
    const [isK500, setIsK500] = useState(false);
    const [isK3000, setIsK3000] = useState(false);
    useEffect(() => {
        if (!iPointsInfo || !iPointsInfo.lpStatus) {
            setIs3day(false);
            setIs5day(false);
            setIsK500(false);
            setIsK3000(false);
            return;
        }
        const found500Object = iPointsInfo.lpStatus.find((item) => item.requiredValue === 500);
        const found3000Object = iPointsInfo.lpStatus.find((item) => item.requiredValue === 3000);
        if (!found500Object) {
            setIsK3000(false);
        }
        if (!found3000Object) {
            setIsK3000(false);
        }
        if (
            (found500Object && found500Object.validHoldingTimeSeconds > 259200 && found500Object.validHoldingTimeSeconds < 432000) ||
            (found3000Object && found3000Object.validHoldingTimeSeconds > 259200 && found3000Object.validHoldingTimeSeconds < 432000)
        ) {
            setIs3day(true);
        }
        if (
            (found500Object && found500Object.validHoldingTimeSeconds > 432000) ||
            (found3000Object && found3000Object.validHoldingTimeSeconds > 432000)
        ) {
            setIs5day(true);
        }

        if (found500Object && found500Object?.rewardIpoints > 0) {
            setIsK500(true);
        }
        if (found3000Object && found3000Object?.rewardIpoints > 0) {
            setIsK3000(true);
        }
        if (iPointsInfo.lpVolume >= 500 && iPointsInfo.lpVolume < 3000) {
            setIsK500(true);
        }
        if (iPointsInfo.lpVolume >= 3000) {
            setIsK3000(true);
        }
        if (iPointsInfo.lpVolume >= 500 && iPointsInfo.lpVolume < 5000) {
            setIsK500(true);
        }
        if (iPointsInfo.lpVolume >= 5000) {
            setIsK3000(true);
        }
    }, [iPointsInfo]);

    const totalLPReward = useMemo(() => {
        if (!iPointsInfo || !iPointsInfo.lpStatus) {
            return 0;
        }
        return iPointsInfo.lpStatus.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.rewardIpoints;
        }, 0);
    }, [iPointsInfo]);

    function getNextMonday(): string {
        const now = new Date();
        const dayOfWeek = now.getDay(); // 0-6, 0 is Sunday, 6 is Saturday
        const daysUntilNextMonday = (1 - dayOfWeek + 7) % 7 || 7; // if today is Sunday, get next Monday, not today
        now.setDate(now.getDate() + daysUntilNextMonday);

        const year = now.getFullYear();
        let month = (now.getMonth() + 1).toString();
        let day = now.getDate().toString();

        // Pad month and day with leading zeros if necessary
        month = month.length < 2 ? '0' + month : month;
        day = day.length < 2 ? '0' + day : day;

        return `${year}-${month}-${day}`;
    }

    const getToken = (address: string) => {
        const tempToken = {
            ...tokenList.find((e) => e.address === address),
        };
        return tempToken;
    };
    return networkLoading ? (
        <Card w={{ base: '100%', sm: '438px', xxl: '488px' }} h="344px" bg={colorTheme('#FDFEFF', '#161322')}>
            <Loading variant={LoadingEnum.purple} text={t('Loading...')} pt="100px" pb="30px" />
        </Card>
    ) : (
        <Card
            w={{ base: '100%', sm: '438px', xxl: '488px' }}
            h={{ base: 'unset', sm: '344px' }}
            bg={colorTheme('#FDFEFF', '#161322')}
            py={{ base: '15px', sm: '0px' }}
            justifyContent="center"
        >
            <Stack
                px={{ base: '17px', sm: '36px' }}
                // mt="21px"
                opacity={account ? 1 : 0.5}
                justifyContent="space-between"
                direction={{ base: 'row', sm: 'row' }}
            >
                <Stack>
                    <Text className={i_text_copy} color="#6A5E86">
                        {t('Weekly Reward')}
                    </Text>
                    <Text className={i_text_copy} fontSize="24px !important" fontWeight="600" color={colorTheme('#24193B', '#F5F5F5')}>
                        {account ? totalWeeklyPoint || 0 : '- -'}
                        <Text as="span" fontSize="20px" color={colorTheme('#442F70', '#8E7BB8')}>
                            {' '}
                            {t('iPoints')}
                        </Text>
                    </Text>
                </Stack>
                <Stack alignItems="end">
                    <HStack>
                        <Image boxSize="15px" src="/assets/iPoints/weeklyReward/time.svg"></Image>
                        <Text className={i_text_copy} color="#6A5E86">
                            {t('End Time')}
                        </Text>
                    </HStack>
                    <Text className={i_text_copy} color={colorTheme('#611DFF', '#996FFF')}>
                        {getNextMonday() + ' 0:00 UTC+8'}
                    </Text>
                </Stack>
            </Stack>
            {account ? (
                <Stack px={{ base: '17px', sm: '30px' }} spacing="6px" mt="14px">
                    <HStack justifyContent="space-between" bg={colorTheme('#F7F8FF', 'rgba(87, 76, 133, 0.17)')} p="14px">
                        <Stack spacing="4px">
                            <Text className={i_text_copy} color={'#A098B4'}>
                                {t('Swap Volume')}
                            </Text>
                            <Text className={i_text_copy} fontWeight="600" color={colorTheme('#291A4B', '#CCBFFF')}>
                                $ {formatNumber(totalSwapVolume || 0, 2, 2, true)}
                            </Text>
                        </Stack>
                        <Divider flex="1" variant="dashed" mx="10px !important" borderColor="#CFCCDE"></Divider>
                        <Stack spacing="4px">
                            <Text className={i_text_copy} color="#A098B4">
                                {t('iPoints Reward')}
                            </Text>
                            <HStack spacing="7px">
                                <Image
                                    boxSize="11px"
                                    src={colorTheme('/assets/iPoints/blueGift.svg', '/assets/iPoints/darkBlueGift.svg')}
                                ></Image>
                                <Text className={i_text_copy} fontWeight="600" color={colorTheme('#291A4B', '#C9BDE6')}>
                                    +{totalSwapPoints}
                                </Text>
                            </HStack>
                        </Stack>
                    </HStack>
                    {spotTokens.length > 0 &&
                        spotTokens.map((item, index) => {
                            return (
                                <HStack
                                    key={index}
                                    justifyContent="space-between"
                                    bg={colorTheme('#F7F8FF', 'rgba(87, 76, 133, 0.17)')}
                                    p="14px"
                                >
                                    <Stack spacing="4px">
                                        <Text className={i_text_copy} color={'#A098B4'}>
                                            ${getToken(item.spotToken).symbol} {t('Swap Volume')}
                                        </Text>
                                        <Text className={i_text_copy} fontWeight="600" color={colorTheme('#291A4B', '#CCBFFF')}>
                                            $ {formatNumber(item.swapVolume, 2, 2, true)}
                                        </Text>
                                    </Stack>
                                    <Divider flex="1" variant="dashed" mx="10px !important" borderColor="#CFCCDE"></Divider>
                                    <Stack spacing="4px">
                                        <Text className={i_text_copy} color="#A098B4">
                                            {t('iPoints Reward')}
                                        </Text>
                                        <HStack spacing="7px">
                                            <Image
                                                boxSize="11px"
                                                src={colorTheme('/assets/iPoints/blueGift.svg', '/assets/iPoints/darkBlueGift.svg')}
                                            ></Image>
                                            <Text className={i_text_copy} fontWeight="600" color={colorTheme('#291A4B', '#C9BDE6')}>
                                                +{item.rewardIpoints}
                                            </Text>
                                        </HStack>
                                    </Stack>
                                </HStack>
                            );
                        })}

                    <HStack justifyContent="space-between" bg={colorTheme('#F7F8FF', 'rgba(87, 76, 133, 0.17)')} py="10px" px="14px">
                        <Stack>
                            <Text className={i_text_copy} color="#A098B4">
                                {t('LP Status')}
                            </Text>
                            <HStack>
                                <LPStatus value={LPStatusDayEnum.day3} isLight={is3day}></LPStatus>
                                <LPStatus value={LPStatusDayEnum.day5} isLight={is5day}></LPStatus>
                            </HStack>
                            <HStack>
                                <LPStatus value={LPStatusValueEnum.k500} isLight={isK500}></LPStatus>
                                <LPStatus value={LPStatusValueEnum.k3000} isLight={isK3000}></LPStatus>
                            </HStack>
                        </Stack>
                        <Divider flex="1" variant="dashed" borderColor="#CFCCDE" mx="10px !important"></Divider>

                        <Stack>
                            <Text className={i_text_copy} color="#A098B4">
                                {t('iPoints Reward')}
                            </Text>
                            <HStack spacing="7px">
                                <Image
                                    boxSize="11px"
                                    src={colorTheme('/assets/iPoints/blueGift.svg', '/assets/iPoints/darkBlueGift.svg')}
                                ></Image>
                                <Text className={i_text_copy} fontWeight="600" color={colorTheme('#291A4B', '#C9BDE6')}>
                                    +{totalLPReward}
                                </Text>
                            </HStack>
                        </Stack>
                    </HStack>
                </Stack>
            ) : (
                <Stack flex="1" px={{ base: '17px', sm: '30px' }} spacing="10px" mt="8px" mb="17px">
                    <Center h="100%" bg={colorTheme('#F7F8FF', 'rgba(87, 76, 133, 0.17)')}>
                        <VStack>
                            <Text className={i_text_copy} color={'#4F54CF'}>
                                Oh! there&apos;s nothing here!
                            </Text>
                            <Text className={i_text_copy} color={'#4F54CF'}>
                                Please connect wallet
                            </Text>
                        </VStack>
                    </Center>
                </Stack>
            )}
        </Card>
    );
};

export default WeeklyReward;
