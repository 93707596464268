import { Image, useColorMode, Text, BoxProps, VStack, HStack } from '@chakra-ui/react';
import { TRADE_LOADING_HINT } from '../../config/trade/config';
import { TokenInfoFormatted } from '../../hooks/useTokenListFormatted';
import { TradeState } from '../../hooks/useTradeLoading';
import { useWeb3WithDefault } from '../../hooks/useWeb3WithDefault';
import { getColorThemeSelector } from '../../utils/funcs';
import { Modal } from '../Modal/Modal';
import { ToastLink } from '../../iZUMi-UI-toolkit/src/components/Toast/Toast';
import { i_h4, i_h5, i_text_copy } from '../../style';
export enum TradeLoadingType {
    add = 'add',
    swap = 'swap',
}
type Props = {
    isOpen: boolean;
    onClose: () => void;
    type?: TradeLoadingType;
    content: string;
    tokenX?: TokenInfoFormatted;
    tokenY?: TokenInfoFormatted;
    tokenXAmount?: string;
    tokenYAmount?: string;
    toastLink?: ToastLink;
    tradeState?: TradeState;
} & BoxProps;
export const TradeLoadingModal: React.FC<Props> = (props) => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { chainId } = useWeb3WithDefault();
    const { isOpen, onClose, type, content, tokenX, tokenY, tokenXAmount, tokenYAmount, toastLink, tradeState, ...rest } = props;

    return !tradeState || tradeState === TradeState.create ? (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            w={{ base: '341px', sm: '540px' }}
            minH={{ base: '383px', sm: '478px' }}
            title=""
            isShowClose={true}
            {...rest}
        >
            <VStack h={{ base: '300px', sm: '310px' }} spacing="10px" pt={{ base: '30px', sm: '20px' }}>
                <Image
                    w="60px"
                    h="60px"
                    src={colorTheme('/assets/loading/loading.gif', '/assets/loading/darkLoading.gif')}
                    fallbackSrc={colorTheme('/assets/loading/loading.gif', '/assets/loading/darkLoading.gif')}
                ></Image>
                <Text
                    className={i_h4}
                    mt="38px !important"
                    color={colorTheme('#2E0069', '#E8D6FF')}
                    textAlign="center"
                    whiteSpace="pre-wrap"
                >
                    {content}
                </Text>

                <Text className={i_text_copy} color={colorTheme('#5D2DAC', '#C29CFF')}>
                    Please sign in your wallet
                </Text>
                {TRADE_LOADING_HINT && TRADE_LOADING_HINT[chainId] && (
                    <HStack w="100%" bg={colorTheme('#F6F4FF', '#3E2C76')} borderRadius="12px" py="14px" px="24px" mt="auto !important">
                        <Image
                            boxSize="17px"
                            src={colorTheme('/assets/swap/LoadingModal/info.svg', '/assets/swap/LoadingModal/darkInfo.svg')}
                            fallbackSrc={colorTheme('/assets/swap/LoadingModal/info.svg', '/assets/swap/LoadingModal/darkInfo.svg')}
                        ></Image>
                        <Text className={i_text_copy} color={colorTheme('#754FFF', '#DBD1FF')}>
                            {TRADE_LOADING_HINT[chainId]}
                        </Text>
                    </HStack>
                )}
            </VStack>
        </Modal>
    ) : (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            w={{ base: '341px', sm: '540px' }}
            minH={{ base: '383px', sm: '478px' }}
            title=""
            isShowClose={true}
            {...rest}
        >
            <VStack h={{ base: '300px', sm: '310px' }} spacing="20px" pt={{ base: '30px', sm: '20px' }}>
                {tradeState === TradeState.success ? (
                    <Image
                        w="60px"
                        h="60px"
                        src={colorTheme('/assets/loading/success.svg', '/assets/loading/success.svg')}
                        fallbackSrc={colorTheme('/assets/loading/success.svg', '/assets/loading/success.svg')}
                    ></Image>
                ) : (
                    <Image
                        w="60px"
                        h="60px"
                        src={colorTheme('/assets/loading/loading.gif', '/assets/loading/darkLoading.gif')}
                        fallbackSrc={colorTheme('/assets/loading/loading.gif', '/assets/loading/darkLoading.gif')}
                    ></Image>
                )}

                <Text
                    className={i_h4}
                    mt="38px !important"
                    color={colorTheme('#2E0069', '#FFFFFF')}
                    textAlign="center"
                    whiteSpace="pre-wrap"
                    opacity={tradeState === TradeState.success ? 1 : 0.5}
                >
                    {content}
                </Text>

                <HStack
                    w={{ base: '100%', sm: '368px' }}
                    h="38px"
                    className={i_h5}
                    lineHeight="22px !important"
                    bg={colorTheme('#F9F9FB', '#0e0a18')}
                    justifyContent="center"
                    borderRadius="6px"
                    opacity={tradeState === TradeState.success ? 1 : 0.5}
                >
                    <HStack spacing="9px">
                        <Image
                            boxSize="18px"
                            src={tokenX ? tokenX.icon : '/assets/tokens/default.svg'}
                            fallbackSrc={tokenX ? tokenX.icon : '/assets/tokens/default.svg'}
                        ></Image>
                        <Text>{tokenXAmount}</Text>
                        <Text color="#B7B1BE">{tokenX ? tokenX.symbol : ''}</Text>
                    </HStack>
                    {tokenY && (
                        <HStack spacing="9px">
                            {!type || type === TradeLoadingType.swap ? (
                                <Image w="18px" h="8px" src={'/assets/loading/rightArrow.svg'}></Image>
                            ) : (
                                <Text color="#B7B1BE">+</Text>
                            )}
                            <HStack spacing="9px">
                                <Image
                                    boxSize="18px"
                                    src={tokenY ? tokenY.icon : '/assets/tokens/default.svg'}
                                    fallbackSrc={tokenY ? tokenY.icon : '/assets/tokens/default.svg'}
                                ></Image>
                                <Text>{tokenYAmount}</Text>
                                <Text color="#B7B1BE">{tokenY ? tokenY.symbol : ''}</Text>
                            </HStack>
                        </HStack>
                    )}
                </HStack>
                <Text
                    className={i_text_copy}
                    fontWeight="600"
                    color="#339DFF"
                    mt="auto !important"
                    _hover={{ opacity: '0.5' }}
                    cursor="pointer"
                    onClick={() => {
                        toastLink && window.open(toastLink.link);
                    }}
                >
                    View on Explorer
                </Text>
            </VStack>
        </Modal>
    );
};
