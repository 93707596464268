import { HStack, Image, Text, BoxProps, useColorMode, Stack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { isGasOrWrappedGasToken } from '../../../../../config/tokens';
import { useWeb3WithDefault } from '../../../../../hooks/useWeb3WithDefault';
import { useGasToken } from '../../../../../state/models/hooks/useGasToken';
import { RootState } from '../../../../../state/store';
import { noto_t1_bold, noto_t3, noto_t3_underline } from '../../../../../style';
import { getColorThemeSelector, toFeeNumber } from '../../../../../utils/funcs';

const TokenInfo: React.FC<BoxProps> = (props) => {
    const { ...rest } = props;
    const { chainId } = useWeb3WithDefault();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { pro, proControlState } = useSelector((state: RootState) => state);

    const tokenXIsGasToken = isGasOrWrappedGasToken(pro.tokenX, chainId);
    const tokenYIsGasToken = isGasOrWrappedGasToken(pro.tokenY, chainId);
    const gasToken = useGasToken();

    return (
        <HStack h="100%" {...rest}>
            <Image boxSize="30px" src={pro.tokenX.icon} fallbackSrc={pro.tokenX.icon}></Image>
            <Stack spacing="2px">
                <HStack>
                    <Text className={noto_t1_bold} color={colorTheme('#3A2F53', '#EEF0F3')}>
                        {proControlState.useGasToken && tokenXIsGasToken ? gasToken.symbol : pro.tokenX.symbol}/
                        {proControlState.useGasToken && tokenYIsGasToken ? gasToken.symbol : pro.tokenY.symbol}
                    </Text>
                    <HStack
                        w="46px"
                        h="18px"
                        borderRadius="2px"
                        bg={colorTheme('#EEEEEE', '#312314')}
                        color={'#83789B'}
                        justifyContent="center"
                    >
                        <Text className={noto_t3}>{toFeeNumber(pro.currentFee) + '%'}</Text>
                    </HStack>
                </HStack>
                <Text className={noto_t3_underline} color={colorTheme('#767676', '#767676')} as="ins">
                    {proControlState.useGasToken && tokenXIsGasToken ? gasToken.symbol : pro.tokenX.symbol}Token
                </Text>
            </Stack>
        </HStack>
    );
};

export default TokenInfo;
