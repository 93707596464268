import { ChainId } from '../../types/mod';

export const SupportFeeTiers = {
    // mainnet
    [ChainId.BSC]: [0.01, 0.04, 0.2, 1], // ${fee}%
    [ChainId.BSCTestnet]: [0.01, 0.04, 0.2, 1],
    [ChainId.Aurora]: [0.01, 0.04, 0.2, 1],
    [ChainId.AuroraTestnet]: [0.01, 0.04, 0.2, 1],
    [ChainId.ETC]: [0.01, 0.04, 0.2, 1],
    [ChainId.Arbitrum]: [0.01, 0.04, 0.2, 1],
    [ChainId.ZkSyncEra]: [0.04, 0.2, 1],
    [ChainId.Matic]: [0.01, 0.04, 0.2, 1],
    [ChainId.Cronos]: [0.01, 0.04, 0.2, 1],
    [ChainId.Scroll]: [0.05, 0.3, 1],
    // testnet
    [ChainId.MantleTest]: [0.01, 0.04, 0.2, 1],
    [ChainId.ZkSyncAlphaTest]: [0.01, 0.04, 0.2, 1],
    [ChainId.ScrollTestL2]: [0.04, 0.2, 1],
    [ChainId.Icplaza]: [0.01, 0.04, 0.2, 1],
    [ChainId.ConfluxESpace]: [0.01, 0.04, 0.2, 1],
    [ChainId.Meter]: [0.01, 0.04, 0.2, 1],
    [ChainId.Telos]: [0.01, 0.04, 0.2, 1],
} as Record<ChainId, FeeTier[]>;
