export const announceConfig =
    process.env.REACT_APP_ENV === 'production'
        ? []
        : [
              {
                  text: 'Reminder: \xa0\xa0  We are on Scroll Alpha Testnet. Feel free to enjoy the blockchain world powered by iZiSwap and Scroll !',
                  icon: '',
                  type: 'link',
                  linkText: 'Check more.',
                  onClick: null,
                  index: 0,
              },
          ];
