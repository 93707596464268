import { Box, Slider, SliderMark, SliderTrack, SliderFilledTrack, SliderThumb, Circle, BoxProps, useColorMode } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { noto_t3, noto_t3_bold } from '../../../../../../style';
import { getColorThemeSelector } from '../../../../../../utils/funcs';

type OrderSliderProps = {
    variant?: string;
    sliderValue: number;
    setEndValue?: any;
} & BoxProps;
const OrderSlider: React.FC<OrderSliderProps> = (props) => {
    const { variant, sliderValue, setEndValue, ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const labelStyles = {
        mt: '8px',
        color: colorTheme('#B3B3B3', '#83789B'),
    };

    // const [changing, setChanging] = useState(false)
    const [localValue, setLocalValue] = useState(sliderValue);
    useEffect(() => {
        setLocalValue(sliderValue);
    }, [sliderValue]);

    const variantBorderColor = variant === 'green' ? colorTheme('2px solid #54AB6B', '2px solid #83789B') : '2px solid #D34D4D';
    const variantThumbColor = variant === 'green' ? '3px solid #12931F' : '3px solid #CB1414';
    const variantSliderTrack = variant === 'green' ? '#12931F' : '#D34D4D';
    return (
        <Box w={{ base: '100%', sm: '243px' }} py="10px" pr="5px" {...rest}>
            <Slider
                onChange={(val) => {
                    // setChanging(true)
                    setLocalValue(val);
                }}
                onChangeEnd={() => {
                    // setChanging(false)
                    setEndValue && localValue && setEndValue(Number(localValue));
                }}
                defaultValue={sliderValue}
                value={localValue}
                focusThumbOnChange={false}
            >
                <SliderMark className={noto_t3} value={0} pt="3px" {...labelStyles}>
                    0%
                </SliderMark>

                <SliderMark className={noto_t3} value={100} ml="-25px" pt="3px" {...labelStyles}>
                    100%
                </SliderMark>
                {localValue < 0 && (
                    <SliderMark className={noto_t3} value={0} zIndex="1" {...labelStyles}>
                        <Circle
                            w="10px"
                            h="10px"
                            bg={colorTheme('#FFFFFF', '#312314')}
                            border={localValue >= 0 ? variantBorderColor : colorTheme('2px solid #B3B3B3', '2px solid #83789B')}
                            borderRadius="50%"
                            ml="-5px"
                            mt="-13px"
                        ></Circle>
                    </SliderMark>
                )}
                {localValue < 25 && (
                    <SliderMark className={noto_t3} value={25} zIndex="1" {...labelStyles}>
                        <Circle
                            w="10px"
                            h="10px"
                            bg={colorTheme('#FFFFFF', '#312314')}
                            border={localValue >= 25 ? variantBorderColor : colorTheme('2px solid #B3B3B3', '2px solid #83789B')}
                            borderRadius="50%"
                            ml="-5px"
                            mt="-13px"
                        ></Circle>
                    </SliderMark>
                )}
                {localValue < 50 && (
                    <SliderMark className={noto_t3} value={50} zIndex="1" {...labelStyles}>
                        <Circle
                            w="10px"
                            h="10px"
                            bg={colorTheme('#FFFFFF', '#312314')}
                            border={localValue >= 50 ? variantBorderColor : colorTheme('2px solid #B3B3B3', '2px solid #83789B')}
                            borderRadius="50%"
                            ml="-5px"
                            mt="-13px"
                        ></Circle>
                    </SliderMark>
                )}
                {localValue < 75 && (
                    <SliderMark className={noto_t3} value={75} zIndex="1" {...labelStyles}>
                        <Circle
                            w="10px"
                            h="10px"
                            bg={colorTheme('#FFFFFF', '#312314')}
                            border={localValue >= 75 ? variantBorderColor : colorTheme('2px solid #B3B3B3', '2px solid #83789B')}
                            borderRadius="50%"
                            mt="-13px"
                            ml="-5px"
                        ></Circle>
                    </SliderMark>
                )}
                {localValue < 100 && (
                    <SliderMark className={noto_t3} value={100} zIndex="1" {...labelStyles}>
                        <Circle
                            w="10px"
                            h="10px"
                            bg={colorTheme('#FFFFFF', '#312314')}
                            border={localValue >= 100 ? variantBorderColor : colorTheme('2px solid #B3B3B3', '2px solid #83789B')}
                            borderRadius="50%"
                            ml="-4px"
                            mt="-13px"
                        ></Circle>
                    </SliderMark>
                )}
                <SliderMark
                    className={noto_t3_bold}
                    value={sliderValue}
                    textAlign="center"
                    color={variantSliderTrack}
                    mt="-20px"
                    ml="-10px"
                    w="35px"
                >
                    {sliderValue}%
                </SliderMark>
                <SliderTrack bg={colorTheme('linear-gradient(90deg, #F1F1F1 -6.17%, #F2F2F2 102.06%)', '#3A2F53')}>
                    <SliderFilledTrack bg={variantSliderTrack} />
                </SliderTrack>
                <SliderThumb _focus={{}} bg="unset">
                    <Circle w="12px" h="12px" bg={colorTheme('#FFFFFF', '#D9D9E6')} border={variantThumbColor}></Circle>
                </SliderThumb>
            </Slider>
        </Box>
    );
};

export default OrderSlider;
