import { useInterval } from 'ahooks';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Stack,
    useColorMode,
    Text,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Popover,
    PopoverTrigger,
    PopoverContent,
    useDisclosure,
    HStack,
    Drawer,
    DrawerContent,
    DrawerOverlay,
} from '@chakra-ui/react';

import Orders from './Orders/Orders';

import { RootState, RootDispatch } from '../../../state/store';
import { getColorThemeSelector } from '../../../utils/funcs';
import { KlineChart } from './KlineChart/KlineChart';
import KlineInfo from './KlineInfo/KlineInfo';
import OrderBook from './OrderBook/OrderBook';
import OrderForm from './OrderForm/OrderForm';
import ChainNotValidBlockWrapper from '../../components/ChainNotValidBlock';
import useIsMobile from '../../../hooks/useIsMobile';
import { noto_t3_bold, noto_h3 } from '../../../style';
import tokens, { tokenSymbol2token } from '../../../config/tokens';
import { useTokenListFormatted, TokenInfoFormatted } from '../../../hooks/useTokenListFormatted';
import {
    RequestIziSwapTransRecord,
    TransRecordTypeEnum,
    getIziSwapTransRecord,
} from '../../../net/iZUMi-endpoints/src/restful/izumiSwapBase';
import { getBaseQuoteOrder } from '../../../state/models/pro/proOrderFormState/funcs';
import { TokenSymbol } from '../../../types/mod';
import { useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';
import { PRO_CONFIG, PRO_DEFAULT_MARKET } from '../../../config/bizConfig';
import OrderBookBlock from './OrderBook/components/OrderBookBlock';
import RecentTradesBlock from './OrderBook/components/RecentTradesBlock';
import { useSearchParams } from '../../../hooks/useSearchParams';
import { useRematchDispatch } from '../../../hooks/useRematchDispatch';
import CustomButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';

export type trades = {
    actionType: string;
    price: number | undefined;
    quantity: number | undefined;
    timestamp: number;
};

const Pro: React.FC = () => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { pro } = useSelector((state: RootState) => state);
    const themeColor = colorTheme('#ffffff', '#221C1B');
    const isMobile = useIsMobile();
    const { chainId } = useWeb3WithDefault();
    const [currentType, setCurrentType] = useState('Chart');
    const history = useHistory();
    const { isOpen, onToggle, onClose } = useDisclosure();

    const [trades, setTrades] = useState([] as trades[]);
    const [actionType, setActionType] = useState('buy');
    const { loading, tokenList } = useTokenListFormatted();
    const searchParams = useSearchParams();
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));

    useEffect(() => {
        const market = searchParams.get('market') ?? undefined;
        const pChainId = searchParams.get('chain_id') ?? undefined;
        if (market && Number(pChainId) === chainId) {
            const parsePath = market.split('_');
            const tokenXSymbol = parsePath[0];
            const tokenYSymbol = parsePath[1];
            const fee = Number(parsePath[2]);
            if (tokenXSymbol && tokenYSymbol && !loading) {
                dispatch.pro.setPoolState({
                    tokenXSymbol: tokenXSymbol,
                    tokenYSymbol: tokenYSymbol,
                    fee: fee,
                    chainId: chainId,
                    tokenList: tokenList,
                });
            }
            return;
        }

        if (Number(pChainId) !== chainId) {
            if (PRO_DEFAULT_MARKET[chainId]) {
                // to init
                history.push('/pro?chain_id=' + chainId + '&market=' + PRO_DEFAULT_MARKET[chainId]?.market ?? '');
                return;
            }
            return;
        }

        if (!pro.pool) {
            if (PRO_DEFAULT_MARKET[chainId]) {
                // to init
                history.push('/pro?chain_id=' + chainId + '&market=' + PRO_DEFAULT_MARKET[chainId]?.market ?? '');
            }
        } else {
            history.push('/pro?chain_id=' + chainId + '&market=' + `${pro.tokenX.symbol}_${pro.tokenY.symbol}_${pro.currentFee}`);
        }
    }, [searchParams, chainId, loading, tokenList, pro.pool]);

    const getToken = (symbol: string) => {
        const tempToken = {
            ...tokenList.find((e) => e.symbol === symbol),
        };
        if (tokens[symbol as TokenSymbol]) {
            const findToken = tokenSymbol2token(symbol, chainId);
            if (findToken.address) {
                return findToken;
            }
        }
        return tempToken;
    };

    const refreshTradesData = () => {
        const queryParams: RequestIziSwapTransRecord = {
            page: 1,
            page_size: 30,
            chain_id: chainId,
            contract_addr: pro.pool,
            type: TransRecordTypeEnum.IZI_SWAP_TOKEN,
        };
        getIziSwapTransRecord(queryParams).then((r) => {
            const data = r.data.is_success ? r.data.data : [];

            if (data.length) {
                const tokenA = getToken(data[0].tokenX) as TokenInfoFormatted;
                const tokenB = getToken(data[0].tokenY) as TokenInfoFormatted;
                const { tokenAIsQuoteToken } = getBaseQuoteOrder(tokenA, tokenB, chainId);
                const result = data.map((item) => {
                    if (tokenAIsQuoteToken) {
                        return {
                            actionType: item.sellXEarnY ? 'buy' : 'sell',
                            price: item.dealPrice && 1 / item.dealPrice,
                            quantity: item.amountY,
                            timestamp: item.timestamp,
                        };
                    } else {
                        return {
                            actionType: item.sellXEarnY ? 'sell' : 'buy',
                            price: item.dealPrice,
                            quantity: item.amountX,
                            timestamp: item.timestamp,
                        };
                    }
                });
                setTrades(result);
            }
        });
    };

    useInterval(() => {
        refreshTradesData();
    }, PRO_CONFIG.ORDERBOOK_AUTO_REFRESH_PRICE_INTERVAL);

    return isMobile ? (
        <ChainNotValidBlockWrapper
            content={
                <Stack w="100%" h="100%" bg={colorTheme('#F1F3FA', '#03010C')} px="12px" overflow="auto">
                    <KlineInfo themeColor={themeColor} />
                    <Stack
                        direction={{ base: 'column', sm: 'row' }}
                        w="100%"
                        mt="12px !important"
                        mb={{ base: '146px !important', sm: '15px !important' }}
                    >
                        <Stack w="100%" px="10px" bg={themeColor} overflow="hidden" borderRadius="6px">
                            <Tabs>
                                <TabList justifyContent="5px">
                                    <Tab
                                        w="40px"
                                        color="#A3A0A9"
                                        px="2px"
                                        _selected={{
                                            color: '#7F4AFE',
                                            borderColor: '#7F4AFE',
                                            borderBottomWidth: '3px',
                                        }}
                                        _hover={{
                                            color: '#7F4AFE',
                                        }}
                                        onClick={() => {
                                            setCurrentType('Chart');
                                        }}
                                    >
                                        <Text className={currentType === 'Chart' ? noto_t3_bold : noto_h3}>Chart</Text>
                                    </Tab>
                                    <Tab
                                        w="80px"
                                        px="2px"
                                        color="#A3A0A9"
                                        ml="5px"
                                        _selected={{
                                            color: '#7F4AFE',
                                            borderColor: '#7F4AFE',
                                            borderBottomWidth: '3px',
                                        }}
                                        _hover={{
                                            color: '#7F4AFE',
                                        }}
                                        onClick={() => {
                                            setCurrentType('OrderBook');
                                        }}
                                    >
                                        <Text className={currentType === 'OrderBook' ? noto_t3_bold : noto_h3}>Order Book</Text>
                                    </Tab>
                                    <Tab
                                        w="100px"
                                        px="2px"
                                        color="#A3A0A9"
                                        ml="5px"
                                        _selected={{
                                            color: '#7F4AFE',
                                            borderColor: '#7F4AFE',
                                            borderBottomWidth: '3px',
                                        }}
                                        _hover={{
                                            color: '#7F4AFE',
                                        }}
                                        onClick={() => {
                                            setCurrentType('RecentTrades');
                                        }}
                                    >
                                        <Text className={currentType === 'RecentTrades' ? noto_t3_bold : noto_h3}>Recent Trades</Text>
                                    </Tab>
                                </TabList>

                                <TabPanels>
                                    <TabPanel py="10px" px="0px">
                                        <KlineChart themeColor={themeColor}></KlineChart>
                                    </TabPanel>
                                    <TabPanel py="10px" px="0px">
                                        <OrderBookBlock trades={trades}></OrderBookBlock>
                                    </TabPanel>
                                    <TabPanel py="10px" px="0px">
                                        <RecentTradesBlock trades={trades} themeColor={themeColor}></RecentTradesBlock>
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>
                        </Stack>
                        <Orders themeColor={themeColor}></Orders>

                        <HStack
                            w="100%"
                            onClick={onToggle}
                            bg={colorTheme('#ffffff', '#221D18')}
                            borderRadius="10px 10px 0px 0px"
                            position="fixed"
                            bottom="60px"
                            left="0px"
                            zIndex="3"
                            p="13px"
                            boxShadow={colorTheme('0px 0px 27px 5px rgba(201, 201, 201, 0.25)', '0px 0px 27px 5px rgba(14, 7, 21, 0.58)')}
                        >
                            <CustomButton
                                className={noto_t3_bold}
                                flex="1 1 0%"
                                h="50px"
                                text={'Buy ' + pro.tokenX.symbol}
                                bg="#54AB6B"
                                color={'#FFFFFF'}
                                borderRadius="4px"
                                onClick={() => {
                                    setActionType('buy');
                                }}
                            ></CustomButton>
                            <CustomButton
                                className={noto_t3_bold}
                                flex="1 1 0%"
                                h="50px"
                                text={'Sell ' + pro.tokenX.symbol}
                                bg="#D34D4D"
                                color={'#FFFFFF'}
                                borderRadius="4px"
                                onClick={() => {
                                    setActionType('sell');
                                }}
                            ></CustomButton>
                        </HStack>
                        <Drawer placement={'bottom'} onClose={onClose} isOpen={isOpen}>
                            <DrawerOverlay />
                            <DrawerContent>
                                <OrderForm themeColor={themeColor} actionType={actionType} setActionType={setActionType}></OrderForm>
                            </DrawerContent>
                        </Drawer>
                    </Stack>
                </Stack>
            }
            app="trade"
        />
    ) : (
        <ChainNotValidBlockWrapper
            content={
                <Stack w="100%" h="100%" bg={colorTheme('#F1F3FA', '#03010C')} px="12px" overflow="auto">
                    <KlineInfo themeColor={themeColor} />
                    <Stack
                        direction={{ base: 'column', sm: 'row' }}
                        w="100%"
                        mt="12px !important"
                        mb={{ base: '70px !important', sm: '15px !important' }}
                    >
                        <Stack h="100%" direction={{ base: 'column', sm: 'column' }}>
                            <Stack
                                h={pro.isShowAllOrders ? '138px' : 'unset'}
                                direction={{ base: 'column', sm: 'row' }}
                                overflow="hidden"
                                transitionProperty="all"
                                transitionDuration="0.5s"
                                transitionTimingFunction="ease"
                            >
                                <KlineChart themeColor={themeColor}></KlineChart>
                                <OrderBook themeColor={themeColor} trades={trades}></OrderBook>
                            </Stack>
                            <Orders themeColor={themeColor}></Orders>
                        </Stack>
                        <Stack>
                            <OrderForm themeColor={themeColor} actionType={actionType} setActionType={setActionType}></OrderForm>
                        </Stack>
                    </Stack>
                </Stack>
            }
            app="trade"
        />
    );
};
export default Pro;

function useInitProState() {
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const { chainId } = useWeb3WithDefault();
    const { pro } = useSelector((state: RootState) => state);

    if (!pro.pool && PRO_DEFAULT_MARKET[chainId]) {
        const { quoteToken, baseToken, tokenAIsQuoteToken } = getBaseQuoteOrder(
            PRO_DEFAULT_MARKET[chainId].tokenX,
            PRO_DEFAULT_MARKET[chainId].tokenY,
            chainId
        );

        dispatch.pro.setPoolInfo({
            tokenX: baseToken,
            tokenY: quoteToken,
            pool: PRO_DEFAULT_MARKET[chainId].pool,
            currentFee: PRO_DEFAULT_MARKET[chainId].fee,
            isReverseToken: tokenAIsQuoteToken,
            isPoolChange: true,
            intervals: [],
        });
    }
}
