import { ChainId } from '../../types/mod';

export const baseURL = 'https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/bridge/';

export const BRIDGE = {
    [ChainId.ZkSyncEra]: [
        {
            titleImgSrc: baseURL + 'era.svg',
            title: 'zkSync Era Bridge',
            content: "zkSync Era's official bridge that can securely bridge Ethereum's native assets in the safest way possible.",
            link: 'https://bridge.zksync.io/',
        },
        {
            titleImgSrc: baseURL + 'cbridge.png',
            title: 'cBridge',
            content:
                'cBridge is powered by Celer network, which is a decentralized and non-custodial asset bridge that supports 40+ blockchains.',
            link: 'https://cbridge.celer.network/1/324/USDC',
        },
        {
            titleImgSrc: baseURL + 'symbiosis.png',
            title: 'Symbiosis',
            content: 'Symbiosis is a cross-chain AMM DEX. You can easily swap any token and move your assets across different networks.',
            link: 'https://app.symbiosis.finance/swap?chainIn=ZkSync%20Era&chainOut=Linea&tokenIn=0x16A9494e257703797D747540f01683952547EE5b&tokenOut=ETH',
        },
        {
            titleImgSrc: baseURL + 'rubic.png',
            title: 'Rubic',
            content: 'Cross-chain technology aggregator + Tools to enable it for dApps.',
            link: 'https://app.rubic.exchange/?fromChain=ZK_SYNC&toChain=BSC&from=iZi&to=iZi',
        },
        {
            titleImgSrc: baseURL + 'orbiter.png',
            title: 'Orbiter',
            content:
                'Orbiter Finance is a decentralized cross-rollup bridge for transferring the Ethereum native assets, which is the infrastructure of Layer 2, it offers low cost and almost instant transfers.',
            link: 'https://www.orbiter.finance/?source=Arbitrum&dest=zkSync%20Era',
        },
    ],
    [ChainId.ScrollTestL2]: [
        {
            titleImgSrc: baseURL + 'scroll.png',
            title: 'Scroll Bridge',
            content: "Scroll's official testnet bridge that can securely bridge Goerli's native assets in the safest way possible.",
            link: 'https://scroll.io/bridge',
        },
    ],
    [ChainId.Scroll]: [
        {
            titleImgSrc: baseURL + 'scroll.png',
            title: 'Scroll Bridge',
            content: "Scroll's official bridge that can securely bridge Ethereum's native assets in the safest way possible.",
            link: 'https://scroll.io/bridge',
        },
        {
            titleImgSrc: baseURL + 'owlto.png',
            title: 'Owlto',
            content:
                'Owlto Finance is a decentralized cross-rollup bridge that focuses on L2, providing "safe, fast, cheap, and easy-to-use" services.',
            link: 'https://owlto.finance/bridge?channel=6564',
        },
    ],
} as any;
